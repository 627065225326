<template>
  <div class="Teamwork" v-fixed>
    <div class="topbar">
      <img @click="$router.go(-1)" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
      <div class="head-title"></div>
    </div>

    <div class="content">
      <!--商务合作  -->
      <!-- <div class="content1">
        <h3>{{ $t("商務合作") }}</h3>
        <p>{{ $t("請複製並添加商務合作Telegram帳號") }}</p>
        <div class="item-list">
          <div class="left">
            <div class="logo"><img :src="brandImg1" alt="" /></div>
            <div class="brand">
              <h3>Telegram</h3>
              <p>{{ name }}</p>
            </div>
          </div>
          <div class="right">
            <div class="copy" :data-clipboard-text="name" @click="copyText()">
              {{ $t("複製") }}
            </div>
          </div>
        </div>
      </div> -->
      <!-- 客服邮箱 -->
      <div class="content1">
        
      <v-img :src='require("@/assets/imgs/DataAnalyse.png")' ></v-img>
        <v-img :src='require("@/assets/imgs/BC.png")' ></v-img>
        <h3>{{ $t("商務合作") }}</h3>
        <p>{{ $t("請複製並添加商務合作Telegram帳號") }}</p> 
        <div class="item-list">
          <div class="left">
            <div class="logo"><img :src="serviceImg" alt="" /></div>
            <div class="brand">
              <h3>Telegram</h3>
              <p>{{ name1 }}</p>
            </div>
          </div>
          <div class="right">
            <div class="copy" :data-clipboard-text="name1" @click="copyText()">
              {{ $t("複製") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      name: "",
      name1: "",
      brandImg1: require("@/assets/imgs/brand-img1.png"),
      serviceImg: require("@/assets/imgs/tel.png"),
    };
  },
  created() {
    // 获取商务合作邮箱
    this.getTeamworkEmail();
  },
  methods: {
    // 获取商务合作邮箱
    getTeamworkEmail() {
      this.$get("/api/Home/WebSettings").then((res) => {
        this.name = res.data.telegram;
        this.name1 = res.data.customerEmail;
      });
    },
    // 复制邮箱
    copyText() {
      var clipboard = new Clipboard(".copy");
      clipboard.on("success", (e) => {
        this.$toast(i18n.t("複製成功"));
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
  directives: {
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "position:fixed;width:100%;top:-" + scrollTop + "px;";
      },
      // unbind 指令与元素解绑时调用
      unbind() {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Teamwork {
  padding-top: 46px;
  background: #F0F6F9;
  height: 100%;
  .topbar {
    display: flex;
    align-items: center;
    text-align: left;
    background-color: #F0F6F9;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #000;
    }
  }
  .content {
    padding: 0.53333rem 0.64rem 0;
    .content1 {
      margin-bottom: 30px;
      h3 {
        font-size: 0.74667rem;
    line-height: 0.53333rem;
    color: #485573;
    text-align: left;
    margin: 0.4rem 0 .2rem 0;
      }
      p {
        font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 0.37333rem;
    line-height: 0.53333rem;
    color: #95B6C7;
    text-align: left;
      }
      .item-list {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          width: 50%;
          display: flex;
          align-items: center;
          .logo {
            width: 1.30667rem;
    height: 1.30667rem;
    padding: 0.08rem;
    border-radius: 50%;
    background-color: #dee7f4;
    margin-right: 0.50667rem;
          }
          .brand {
            h3 {
              font-family: "PingFang SC";
    font-style: normal;
    font-weight: 700;
    font-size: 0.48rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #485573;
    text-align: left;
            }
            p {
              font-size: 0.37333rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #666666;
    text-align: left;
            }
          }
        }
        .right {
          display: flex;
          justify-content: end;
          .copy {
            display: flex;
    justify-content: center;
    align-items: center;
    background: #00C7DB;
    border-radius: 0.26667rem;
    font-size: 0.37333rem;
    color: #ffffff;
    padding: 0.1rem 0.4rem;
          }
        }
      }
    }
  }
}
</style>